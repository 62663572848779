import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import { Role, AuthUser, internalRoles } from "@/resources/interfaces";
import type { RouteConfig, Route } from "vue-router";
import { difference } from "lodash";

const Index = () => import("@/views/dashboard/Index.vue");
const ApplicationList = () => import("@/views/application/ApplicationList.vue");
const ApplicationDetail = () =>
  import("@/views/application/ApplicationDetail.vue");
const ConsumerCreditProfile = () =>
  import("@/views/application/ConsumerCreditProfile.vue");
const BusinessList = () => import("@/views/business/BusinessList.vue");
const BusinessDetail = () => import("@/views/business/BusinessDetail.vue");
const ConfigList = () => import("@/views/config/ConfigList.vue");
const ConfigDetail = () => import("@/views/config/ConfigDetail.vue");
const UsStates = () => import("@/views/config/UsStates.vue");
const IsoList = () => import("@/views/iso/IsoList.vue");
const IsoDetail = () => import("@/views/iso/IsoDetail.vue");
const SyndicatorList = () => import("@/views/syndicator/SyndicatorList.vue");
const SyndicatorDetail = () =>
  import("@/views/syndicator/SyndicatorDetail.vue");
const SyndicatorProfit = () =>
  import("@/views/syndicator/SyndicatorProfit.vue");
const OfferCalculator = () => import("@/views/offer/OfferCalculator.vue");
// const Activation = () => import("@/views/offer/Activation.vue");
const PaymentGenerator = () =>
  import("@/views/application/PaymentGenerator.vue");
const GeneralLedger = () => import("@/views/accounting/GeneralLedger.vue");
const PaymentSchedule = () => import("@/views/accounting/PaymentSchedule.vue");
const UploadActivityDetail = () =>
  import("@/views/accounting/UploadActivityDetail.vue");
const UploadActivityList = () =>
  import("@/views/accounting/UploadActivityList.vue");
const ProductList = () => import("@/views/product/ProductList.vue");
const ProductDetail = () => import("@/views/product/ProductDetail.vue");
const SyndicationSetup = () =>
  import("@/views/application/SyndicationSetup.vue");
const ContractDrafting = () => import("@/views/offer/ContractDrafting.vue");
const FileDownload = () => import("@/views/file/FileDownload.vue");
const UserList = () => import("@/views/user/UserList.vue");
const UserDetail = () => import("@/views/user/UserDetail.vue");
const Profile = () => import("@/views/user/Profile.vue");
const CustomerServiceTaskList = () =>
  import("@/views/customerServiceTask/CustomerServiceTaskList.vue");
const SitePolicy = () => import("@/views/pages/SitePolicy.vue");

Vue.use(Router);

const isKAM = process.env.VUE_APP_KAM === "1";
const filterRoutes = (routes: RouteConfig[]): RouteConfig[] => {
  return routes.filter((route) => {
    if (route.children) {
      route.children = filterRoutes(route.children);
    }
    return !route.meta?.disabled;
  });
};

const routeConfig = [
  {
    path: "/",
    component: Index,
    children: [
      {
        name: "Dashboard",
        path: "",
        component: () => import("@/views/dashboard/Dashboard.vue"),
        meta: {
          icon: "mdi-view-dashboard",
          title: "Dashboard",
          roles: internalRoles,
        },
      },
    ],
  },
  {
    path: "/accounting",
    name: "Accounting",
    component: Index,
    redirect: "/accounting/general-ledger",
    meta: {
      icon: "mdi-credit-card-multiple",
      title: "Accounting",
    },
    children: [
      {
        path: "general-ledger",
        name: "General Ledger",
        component: GeneralLedger,
        meta: {
          keepAlive: true,
          roles: [Role.ACCOUNTING, Role.TREASURY],
          title: "General Ledger",
        },
      },
      {
        path: "payment-schedule/:date?",
        name: "Payment Schedule",
        component: PaymentSchedule,
        meta: {
          disabled: isKAM,
          roles: [Role.ACCOUNTING, Role.TREASURY],
          title: "Payment Schedule",
        },
      },
      {
        path: "upload-activity",
        name: "Upload Activities",
        component: UploadActivityList,
        meta: {
          keepAlive: true,
          roles: [Role.ACCOUNTING, Role.TREASURY],
          title: "Upload Activity",
        },
      },
      {
        path: "/upload-activity/:id",
        name: "Upload Activity",
        component: UploadActivityDetail,
        meta: { roles: [Role.ACCOUNTING, Role.TREASURY] },
      },
    ],
  },
  {
    path: "/application",
    component: Index,
    redirect: isKAM ? "/application/advance" : "/application/submission",
    children: [
      {
        path: "submission",
        name: "Submission List",
        component: ApplicationList,
        meta: {
          keepAlive: true,
          disabled: isKAM,
          icon: "mdi-file-send",
          title: "Submissions",
          roles: internalRoles,
        },
        props: { isAdvance: false },
      },
      {
        path: "advance",
        name: "Advance List",
        component: ApplicationList,
        meta: {
          keepAlive: true,
          icon: "mdi-file-document",
          title: "Advances",
          roles: internalRoles,
        },
        props: { isAdvance: true },
      },
      {
        path: "add",
        name: "New Application",
        component: ApplicationDetail,
        props: { add: true },
        meta: { roles: internalRoles },
      },
      {
        path: ":id",
        name: "Application Detail",
        component: ApplicationDetail,
        meta: { keepAlive: false, roles: internalRoles },
      },
      {
        path: ":applicationId/contract",
        name: "Contract Drafting",
        component: ContractDrafting,
        meta: { roles: internalRoles },
      },
      {
        path: ":applicationId/syndication",
        name: "Syndication",
        component: SyndicationSetup,
        meta: { roles: internalRoles },
      },
      // {
      //   path: ":applicationId/activation",
      //   name: "Funding",
      //   component: Activation,
      // },
      {
        path: ":id/payment-generator",
        name: "Payment Generator",
        component: PaymentGenerator,
        meta: { roles: internalRoles },
      },
    ],
  },
  {
    path: "/consumer-credit-profile",
    component: Index,
    children: [
      {
        path: ":id",
        name: "Consumer Credit Profile",
        component: ConsumerCreditProfile,
        meta: { roles: internalRoles },
      },
    ],
  },
  {
    path: "/customer-service-task",
    component: Index,
    redirect: "/customer-service-task/index",
    meta: { disabled: isKAM },
    children: [
      {
        path: "index",
        name: "CS Task List",
        component: CustomerServiceTaskList,
        meta: {
          keepAlive: true,
          icon: "mdi-check",
          title: "CS Tasks",
          roles: [Role.CUSTOMER_SERVICE, Role.ACCOUNTING, Role.TREASURY],
        },
      },
    ],
  },
  {
    path: "/business",
    component: Index,
    redirect: "/business/index",
    children: [
      {
        path: "index",
        name: "Merchant List",
        component: BusinessList,
        meta: {
          keepAlive: true,
          icon: "mdi-store",
          title: "Merchants",
          roles: internalRoles,
        },
      },
      {
        path: "add",
        name: "New Business",
        component: BusinessDetail,
        props: { add: true },
        meta: { roles: [Role.SCRUBBER, Role.ISO] },
      },
      {
        path: ":id",
        name: "Business Detail",
        component: BusinessDetail,
        meta: {
          keepAlive: false,
          roles: internalRoles,
        },
      },
    ],
  },
  {
    path: "/config",
    component: Index,
    redirect: "/config/index",
    children: [
      {
        path: "index",
        name: "Config List",
        component: ConfigList,
        meta: {
          keepAlive: true,
          icon: "mdi-cog-outline",
          title: "Configuration",
          superAdminOnly: true,
        },
      },
      {
        path: "us-states",
        name: "States of USA",
        component: UsStates,
        meta: {
          keepAlive: true,
          roles: internalRoles,
        },
      },
      {
        path: ":key",
        name: "Config Item",
        component: ConfigDetail,
        meta: {
          superAdminOnly: true,
        },
      },
    ],
  },
  {
    path: "/iso",
    component: Index,
    redirect: "/iso/index",
    children: [
      {
        path: "index",
        name: "ISO List",
        component: IsoList,
        meta: {
          keepAlive: true,
          icon: "mdi-account-cash",
          title: "ISOs",
          roles: difference(internalRoles, [Role.DRAFTER]),
        },
      },
      {
        path: "add",
        name: "New ISO",
        component: IsoDetail,
        props: { add: true },
        meta: { roles: [Role.ADMIN] },
      },
      {
        path: ":id",
        name: "ISO Detail",
        component: IsoDetail,
        meta: { roles: difference(internalRoles, [Role.DRAFTER]) },
      },
    ],
  },
  {
    path: "/syndicator",
    component: Index,
    redirect: "/syndicator/index",
    children: [
      {
        path: "index",
        name: "Syndicator List",
        component: SyndicatorList,
        meta: {
          keepAlive: true,
          icon: "mdi-currency-usd",
          title: "Syndicators",
          roles: [Role.TREASURY, Role.ACCOUNTING],
        },
      },
      {
        path: "add",
        name: "New Syndicator",
        component: SyndicatorDetail,
        props: { add: true },
        meta: { roles: [Role.TREASURY, Role.ACCOUNTING] },
      },
      {
        path: ":id",
        name: "Syndicator Detail",
        component: SyndicatorDetail,
        meta: { roles: [Role.TREASURY, Role.ACCOUNTING] },
      },
      {
        path: ":id/profit",
        name: "Syndicator Profit Report",
        component: SyndicatorProfit,
        meta: { roles: [Role.TREASURY, Role.ACCOUNTING] },
      },
    ],
  },
  {
    path: "/offer",
    component: Index,
    children: [
      {
        path: "calculator",
        name: "Offer Calculator",
        component: OfferCalculator,
        meta: { roles: internalRoles.concat([Role.VISITOR, Role.ISO]) },
      },
    ],
  },
  {
    path: "/file",
    component: Index,
    children: [
      {
        path: ":id",
        name: "File Download",
        component: FileDownload,
        meta: { roles: internalRoles },
      },
    ],
  },
  {
    path: "/product",
    redirect: "/product/index",
    component: Index,
    meta: { disabled: isKAM },
    children: [
      {
        path: "index",
        name: "All Products",
        component: ProductList,
        meta: {
          keepAlive: true,
          icon: "mdi-file-table-box-multiple-outline",
          title: "Products",
          superAdminOnly: true,
        },
      },
      {
        path: ":id",
        name: "Product Detail",
        component: ProductDetail,
        meta: {
          superAdminOnly: true,
        },
      },
    ],
  },
  {
    path: "/user",
    component: Index,
    redirect: "/user/index",
    children: [
      {
        path: "index",
        name: "User List",
        component: UserList,
        meta: {
          keepAlive: true,
          icon: "mdi-account",
          title: "Users",
          superAdminOnly: true,
        },
      },
      {
        path: "add",
        name: "New User",
        component: UserDetail,
        props: { add: true },
        meta: { superAdminOnly: true },
      },
      {
        path: "profile",
        name: "Profile",
        component: Profile,
        meta: { roles: internalRoles },
      },
      {
        path: ":id",
        name: "User Detail",
        component: UserDetail,
        meta: { keepAlive: false, roles: internalRoles },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/views/pages/Index.vue"),
    children: [
      // {
      //   name: "Lock",
      //   path: "lock",
      //   component: () => import("@/views/pages/Lock.vue"),
      // },
      {
        name: "Login",
        path: "login",
        alias: "visitor",
        component: () => import("@/views/pages/Login.vue"),
        meta: { isPublic: true },
      },
      {
        name: "Terms of Use",
        path: "terms-of-use",
        component: SitePolicy,
        meta: { isPublic: true },
      },
      {
        name: "Privacy Policy",
        path: "privacy-policy",
        component: SitePolicy,
        meta: { isPublic: true },
      },
    ],
  },
  {
    path: "*",
    component: () => import("@/views/pages/Index.vue"),
    children: [
      {
        name: "404 Error",
        path: "",
        component: () => import("@/views/pages/Error.vue"),
        meta: { isPublic: true },
      },
    ],
  },
];
const routes = filterRoutes(routeConfig);
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  const queryToken = to.query.token;
  const isVisitor = store.state.user?.role == Role.VISITOR;

  if (queryToken) {
    if (isVisitor || !localStorage.getItem("token")) {
      store.commit("SET_TOKEN", queryToken);
    }
  }

  const { loggedIn, passwordExpired } = store.getters;

  if (!loggedIn && !to.meta?.isPublic && to.path !== "/login") {
    next({ path: "/login", query: { redirect: to.fullPath } });
  } else if (loggedIn) {
    if (to.path === "/login") {
      next({ path: "/" });
    } else if (to.path !== "/user/profile" && passwordExpired) {
      next({ path: "/user/profile" });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  if (to.name) {
    document.title = to.name + " - Kalamata Cash";
  }
  const valid = checkPermission(to, store.state.user);
  if (!valid) {
    store.commit("SET_FORBIDDEN", true);
  }
});

const flatRoutes = (routes: RouteConfig[], parentPath = ""): RouteConfig[] => {
  return routes.reduce((acc, route) => {
    const postFix = parentPath && parentPath.endsWith("/") ? "" : "/";
    const path = `${parentPath ? parentPath + postFix : ""}` + route.path;
    const { name, meta, children } = route;
    acc.push({ name, meta, path });
    if (children) {
      acc.push(...flatRoutes(children, path));
    }
    return acc;
  }, [] as RouteConfig[]);
};

export const flatRouteList = flatRoutes(routeConfig);

export const checkPermission = (
  route: Route,
  user: AuthUser | null
): boolean => {
  if (route.meta?.isPublic) return true;
  const roles = (route.meta?.roles || []) as (Role | undefined)[];
  const superAdminOnly = route.meta?.superAdminOnly as boolean | undefined;
  const isAdmin = user?.role === Role.ADMIN;

  if (superAdminOnly && !(isAdmin && user?.isManager)) {
    return false;
  }

  if (isAdmin) {
    // we don't have to list admin in route meta roles
    return true;
  }

  return roles.includes(user?.role);
};

export default router;
